<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >Education Details (Document  shall be added only in PDF format)</span
        >
      </v-flex>

      <v-flex xs12 pt-2>
        <v-layout wrap pt-4 justify-start>
          <v-flex xs12 sm6 md6 lg6 pr-2 v-if="mydocs && mydocs.employeeDetails">
            <span class="kumbhBold">
            Educational Details
          </span>
            <v-textarea readonly hide-details="true" v-model="mydocs.employeeDetails.education" class="pt-2"   outlined
            dense></v-textarea>
          </v-flex>

          <v-flex xs12 sm6 md6 lg6 v-if="mydocs && mydocs.documentDetails">
            <span class="kumbhBold">
              Additional Qualification
          </span>
            <v-textarea readonly hide-details="true" v-model="mydocs.documentDetails.additionalQualification" class="pt-2"   outlined
            dense></v-textarea>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-3 v-if="mydocs && mydocs.documentDetails">
            <span class="kumbhBold">
              External Traing Or Workshop
          </span>
            <v-textarea  readonly hide-details="true" v-model="mydocs.documentDetails.externalTraingOrWorkshop" class="pt-2" outlined
            dense></v-textarea>
          </v-flex>
        
        </v-layout>
          

        <!-- Additional Degree Details Section -->
        <v-divider class="my-6"></v-divider>
        
        <v-layout wrap>
          <v-flex xs12>
            <span class="kumbhBold" style="font-size: 16px">Add Degree Details</span>
          </v-flex>
        </v-layout>
        <v-flex pt-3> 


        <v-card outlined class="pa-4">

        <v-layout wrap pt-4 v-for="(degree, index) in degrees" :key="index">

          <v-flex xs12>
            
              <v-layout wrap align-center>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="degree.degreeName"
                    label="Degree Name"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-flex>
             
                <v-flex xs12 sm6 text-right v-if="degree.degreeFile.length>0">
                  <v-layout wrap>
                    <v-flex xs12>
                      <a target="_blank" :href="mediaURL + degree.degreeFile">
                        <v-btn
                          style="border-radius: 14px; border: 0.5px solid #707070"
                          px-5
                          pt-0
                          text
                          small
                          outlined
                        >
                          View Degree Certificate  
                        </v-btn>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm5 text-right v-else>
                  <span style="color: red; font-size:12px">Degree Certificate  Not Uploaded</span>
                </v-flex>
                <!-- <v-flex xs12 sm6 text-right v-else>
                  <v-chip
                    v-if="degree.degreeFile.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <span>{{ degree.degreeFile.name.slice(0, 40) }}
                      <span v-if="degree.degreeFile.name.length > 40">...</span>
                    </span>
                  </v-chip>
                </v-flex> -->
              </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
      </v-flex>
    </v-layout>

    <!-- Confirmation Dialog -->
 <v-dialog persistent v-model="deleteDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Delete Degree</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn
                  icon
                  color="white"
                  text
                  @click="deleteDialog = false"
                >
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px"
                  >Are you sure you want to delete this degree?</span
                >
              </v-flex>

              <v-flex xs12 align-self-center text-right pt-3>
                <v-btn     @click="deleteDialog = false" color="white">
                  <span class="kumbhBold" style="color: black"> Cancel </span>
                </v-btn> &nbsp;
                <v-btn   @click="confirmDelete" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Delete </span>
                </v-btn></v-flex
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
 </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      twelthDMC: "",
      tenthDMC: "",
      graduationDegree: "",
      postGraduationDegree: "",
      cv: "",
      degrees: [
        {degreeName: "", degreeFile: ""}
      ],
      otherDegrees: [],
      degreeFiles: [],
      formData: new FormData(),
      mydocs:{},

      deleteDialog: false,
      deleteDegreeId: null,
      deleteDegreeIndex: null, 
    };
  },
  mounted() {
    //this.getData();
    this.getMyUploads();
  },
  methods: {
    twelthDMCUpload(event) {
        this.twelthDMC = event.target.files[0];
      },
      tenthDMCUpload(event) {
        this.tenthDMC = event.target.files[0];
      },
      graduationDegreeUpload(event) {
        this.graduationDegree = event.target.files[0];
      },
      postGraduationDegreeUpload(event) {
        this.postGraduationDegree = event.target.files[0];
      },
      // cvUpload(event) {
      //   this.cv = event.target.files[0];
      // },

//       degreeFileUpload(event, index) {
//   const file = event.target.files[0];
//   this.degrees[index].degreeFile = file ? file.name : ''; // Store file name as a string
// },
 degreeFileUpload(event, index) {
      this.degrees[index].degreeFile = event.target.files[0];
    },
    addDegreeField() {
      if (this.degrees.length < 5) {
        this.degrees.push({degreeName: "", degreeFile: ""});
      }
    },
    removeDegree(index) {
  this.degrees.splice(index, 1); // Remove the degree at the given index

  // Only add a blank degree if the array is empty (not when it's 1)
  if (this.degrees.length === 0) {
    this.degrees.push({ degreeName: "", degreeFile: "" });
  }
},
    getMyUploads() {
    this.appLoading = true;
    axios({
      url: "/educationdetails/view",
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        id: this.$route.query.id,
      },
    })
      .then((response) => {
        this.appLoading = false;
        if (response.data.status) {
          this.mydocs = response.data.data;

          // Populate degrees array with response data for "otherDegree"
          if (this.mydocs.documentDetails.otherDegree) {
            this.degrees = this.mydocs.documentDetails.otherDegree.map(degree => ({
              _id: degree._id,
              degreeName: degree.degreeName,
              degreeFile: degree.degreeFile
            }));
          }

        } else {
          this.showSnackBar = true;
          this.msg = response.data.msg;
        }
      })
      .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.log(err);
      });
  },

  validateForm() {
     
     

      // Validate that all required files are uploaded
      

      if (!this.tenthDMC && !this.mydocs?.documentDetails?.tenthDMC) {
        this.msg = "Please upload tenth certificate";
        this.showSnackBar = true;
        return false;
      }
      if (!this.twelthDMC && !this.mydocs?.documentDetails?.twelthDMC) {
        this.msg = "Please upload twelth certificate";
        this.showSnackBar = true;
        return false;
      }

      if (!this.graduationDegree && !this.mydocs?.documentDetails?.graduationDegree) {
        this.msg = "Please upload graduation certificate";
        this.showSnackBar = true;
        return false;
      }

      if (!this.postGraduationDegree && !this.mydocs?.documentDetails?.postGraduationDegree) {
        this.msg = "Please upload post-graduation certificate";
        this.showSnackBar = true;
        return false;
      }

      
      // if (!this.cv && !this.mydocs?.documentDetails?.cv) {
      //   this.msg = "Please upload CV";
      //   this.showSnackBar = true;
      //   return false;
      // }

      // Validate file sizes (max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (this.twelthDMC && this.twelthDMC.size > maxSize) {
        this.msg = "Tenth certificate size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

      if (this.tenthDMC && this.tenthDMC.size > maxSize) {
        this.msg = "Twelth certificate file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

      if (this.graduationDegree && this.graduationDegree.size > maxSize) {
        this.msg = "Graduation certificate file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }
      if (this.postGraduationDegree && this.postGraduationDegree.size > maxSize) {
        this.msg = "Post-Graduation certificate file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }
      // if (this.cv && this.cv.size > maxSize) {
      //   this.msg = "CV size should be less than 5MB";
      //   this.showSnackBar = true;
      //   return false;
      // }

      return true;
    },

    validateAndAdd() {
      if (this.validateForm()) {
        this.add();
      }
    },


    add() {
  let formData = new FormData();
  formData.append("id", localStorage.getItem("userid"));

  // Add individual files
  formData.append("twelthDMC", this.twelthDMC);
  formData.append("tenthDMC", this.tenthDMC);
  formData.append("graduationDegree", this.graduationDegree);
  formData.append("postGraduationDegree", this.postGraduationDegree);
  formData.append("CV", this.cv);

  // Prepare degrees array
  const degreesArray = this.degrees
    .filter(degree => degree.degreeName && degree.degreeFile)  // Only include entries with both fields filled
    .map(degree => ({
      degreeName: degree.degreeName,
      degreeFile: degree.degreeFile.length > 0 ? degree.degreeFile : degree.degreeFile.name // Use the file name here
    }));

  // const degreesArray = this.degrees.map(degree => ({
  //   degreeName: degree.degreeName,
  //   degreeFile: expe.experienceFile.length > 0 ? expe.experienceFile : expe.experienceFile.name // Use existing file path or new file name
  // }));


  // Add degrees array to formData as a JSON string
  formData.append("otherDegree", JSON.stringify(degreesArray));

  // Append actual files to formData
  this.degrees.forEach((degree) => {
    if (degree.degreeFile) {
      formData.append("degreeFile", degree.degreeFile);
    }
  });

  // Send form data via Axios
  axios({
    method: "POST",
    url: "/employee/educationdetails/upload",
    data: formData,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.data.status == true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.postGraduationDegree = "";
        this.tenthDMC = "";
        this.graduationDegree = "";
        this.twelthDMC = "";
        this.getMyUploads();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
openDeleteDialog(degreeId, index) {
      this.deleteDegreeId = degreeId;
      this.deleteDegreeIndex = index;
      this.deleteDialog = true;
    },

    // Confirm deletion and call deleteDegree()
    confirmDelete() {
      this.deleteDialog = false;
      this.deleteDegree(this.deleteDegreeId, this.deleteDegreeIndex);
    },

    deleteDegree(degree, index) {
      const data = { otherDegreeId: degree };

      axios({
        url: "/employee/document/deleteOtherDegree",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Successfully";
            this.showsnackbar = true;
            this.getMyUploads();
            // Remove the degree locally after successful deletion from the server
            this.degrees.splice(index, 1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

  },
};
</script>